<template lang="pug">
div(id='top' class='is-simple-mode')
  ClientOnly
    LazyEmployeeToolbar(v-if='isIntegrationEnabled("cognito") && $cognito.isLoggedIn() && $cognito.isSalesEmployee()')

  HeaderLinkBar(is-simple)
  Header(is-simple)
  slot
  Footer(is-simple)
</template>

<script setup lang="ts">
const { $cognito } = useNuxtApp()
const { isIntegrationEnabled } = useUtils()
</script>
